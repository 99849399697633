<template>
  <div class="min-h-full flex flex-col">
    <div>
      <div class="flex justify-between items-center mb-10">
        <h4 class="text-3xl sm:text-4xl font-bold leading-tight">
          Write your questions for {{ guest.name }}
        </h4>
        <!-- Step counter -->
        <StepsCounter class="ml-8" v-if="steps.length" :stepCount="steps.length" :currentStep="currentStep"/>
      </div>
      <div class="mt-6 sm:mt-10 text-gray-500 font-light text-xl">
        Keep in mind that {{ guest.name }} may not answer every question. Please be respectful with your questions. You should also add a couple additional questions, so that {{ guest.name }} can select the best options.
      </div>
    </div>

    <div class="mt-12 space-y-12">
      <ValidationObserver v-if="questions.length < selectedBookingOption.amount + 2" ref="miniviewQuestionsForm" v-slot="{ handleSubmit }" tag="div">
        <form method="post" @submit.prevent="handleSubmit(saveQuestion)">
          <div>
            <ValidationProvider mode="eager" rules="required" name="Question" :vid="`question`" v-slot="{ errors }" tag="div">
              <div class="relative">
                <label for="question" class="sr-only">Add Question</label>
                <input type="text" id="question" name="question" class="form-input rounded-full focus:border-pink-500 focus:shadow-none py-3 px-4 w-full" :class="{'border-red-500': errors.length}" placeholder="Write your question here..." v-model="form.question">
                <div class="absolute right-0 inset-y-0 flex items-center justify-center mr-2">
                  <button type="submit" class="rounded-full h-9 w-9 bg-indigo-gradiant text-white flex justify-center items-center p-2 hover:opacity-75" title="Add Question">
                    <PlusIcon class="w-full h-full" />
                  </button>
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="mt-4 flex sm:flex-row flex-col items-start sm:items-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
            <modal :with-close-button="false" class="bg-pink-500 text-sm text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
              <VideoIcon class="text-pink-500 h-4" />
              <span class="ml-3">Record a video</span>

              <template #content="{ close }">
                <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                  <RecordVideoQuestion @close="close" @update-question="addQuestion" />
                </div>
              </template>
            </modal>

            <modal class="bg-pink-500 text-sm text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
              <MicIcon class="text-pink-500 h-4" />
              <span class="ml-3">Record an audio</span>

              <template #content="{ close }">
                <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                  <RecordAudioQuestion @close="close" @update-question="addQuestion" />
                </div>
              </template>
            </modal>
          </div>
        </form>
      </ValidationObserver>

      <div>
        <h4 class="text-2xl text-blue-800">
          <template v-if="questions.length > selectedBookingOption.amount">
            <span class="font-bold">{{ selectedBookingOption.amount }}</span> of {{ selectedBookingOption.amount }} questions (+{{ questions.length - selectedBookingOption.amount }} additional)
          </template>
          <template v-else>
            <span class="font-bold">{{ questions.length }}</span> of {{ selectedBookingOption.amount }} questions
          </template>
        </h4>

        <div class="mt-4">
          <div v-if="! questions.length" class="h-12 mb-2 flex justify-center items-center text-sm text-gray-500 bg-gray-50 rounded-lg border-2 border-dashed opacity-75">
            No questions added.
          </div>

          <div v-else class="space-y-4">
            <div v-for="(question, index) in questions" :key="`question-${index}`" class="border-2 border-guestio-gray-100 px-4 py-3 bg-white booking-option-shadow rounded-lg">
              <div class="flex justify-between">
                <div class="flex items-start">
                  <DotsIcon class="h-4 w-4 flex-shrink-0 mt-1.5" />
                  <h5 class="ml-3 text-lg font-bold text-blue-800">
                    {{ index + 1 }}. {{ question.body }}
                  </h5>
                </div>
                <div>
                  <ConfirmDialog
                    @confirm="removeQuestion(index, $event)"
                    confirm-action="Yes, Delete"
                    dialog-title="Delete Question?"
                    dialog-text="Are you sure you want to delete this question?"
                    class="text-red-500 mt-1.5"
                  >
                    <TrashIcon class="h-5 w-5" />
                  </ConfirmDialog>
                </div>
              </div>

              <div v-if="question.media" class="mt-3 flex justify-between items-center space-x-4">
                <template v-if="question.media.collection_name == 'audio_recordings'">
                  <AudioPreview :media-src="question.media.url" class="flex-1" />

                  <modal ref="previewModal" :with-close-button="false" class="text-pink-500 space-x-2 flex items-center text-sm">
                    <EditAltIcon class="h-5 w-5" />
                    <span>Edit</span>

                    <template #content="{ close }">
                      <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                        <RecordAudioQuestion @close="close" :question="question" @update-question="updateQuestion(index, $event)" />
                      </div>
                    </template>
                  </modal>
                </template>

                <template v-else-if="question.media.collection_name == 'video_recordings'">
                  <div class="w-32 flex-shrink-0">
                    <div class="overflow-hidden relative pb-2/3 rounded-lg">
                      <div class="absolute inset-0 z-10 bg-red-300">
                        <video
                          class="w-full h-full object-cover"
                          playsinline
                          looping="false"
                          :src="question.media.url"
                        ></video>
                      </div>
                      <div class="absolute inset-0 z-20 flex items-center justify-center">
                        <button type="button" @click="$refs.previewModal[index].toggle()">
                          <PlayAltIcon class="h-6 w-6 text-white" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <modal ref="previewModal" :with-close-button="false" class="text-pink-500 space-x-2 flex items-center text-sm">
                      <EditAltIcon class="h-5 w-5" />
                      <span>Edit</span>

                      <template #content="{ close }">
                        <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                          <RecordVideoQuestion @close="close" :question="question" @update-question="updateQuestion(index, $event)" />
                        </div>
                      </template>
                    </modal>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="flex justify-center">
          <button @click="saveMiniviewQuestions" type="button" class="rounded-full h-16 sm:h-20 bg-indigo-gradiant text-white text-xl font-bold flex justify-center items-center uppercase px-16 disabled:opacity-50" :disabled="loading || questions.length < selectedBookingOption.amount">
            <span v-if="loading">
              <loading-icon class="h-5 w-5"/>
            </span>
            <span v-else>Continue</span>
          </button>
        </div>
        <div class="text-center mt-4">
          <button @click.prevent="prevStep" type="button" class="text-sm text-pink-500 hover:underline">Previous step</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StepsCounter from '@/components/StepsCounter'
  import ConfirmDialog from '@/components/shared/ConfirmDialog'
  import Modal from '@/components/shared/Modal'
  import PlusIcon from '@/components/svgs/PlusIcon'
  import EditAltIcon from '@/components/svgs/EditAltIcon'
  import TrashIcon from '@/components/svgs/TrashIcon'
  import DotsIcon from '@/components/svgs/DotsIcon'
  import VideoIcon from '@/components/svgs/VideoIcon'
  import MicIcon from '@/components/svgs/MicIcon'
  import PlayAltIcon from '@/components/svgs/PlayAltIcon'
  import RecordVideoQuestion from '@/components/Book/guest/RecordVideoQuestion'
  import RecordAudioQuestion from '@/components/Book/guest/RecordAudioQuestion'
  import AudioPreview from '@/components/shared/AudioPreview'

  export default {
    components: {
      StepsCounter,
      ConfirmDialog,
      Modal,
      EditAltIcon,
      PlusIcon,
      TrashIcon,
      DotsIcon,
      VideoIcon,
      MicIcon,
      PlayAltIcon,
      RecordVideoQuestion,
      RecordAudioQuestion,
      AudioPreview,
    },

    data() {
      return {
        loading: false,
        form: {
          question: ''
        },

        questions: [],
      }
    },

    computed: {
      steps() {
        return this.$store.getters['guestBooking/enabledSteps']
      },

      currentStep() {
        return this.$store.state.guestBooking.currentStep
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      show() {
        return this.$store.getters['guestBooking/show']
      },

      guest() {
        return this.$store.getters['guest/getGuest']
      },

      selectedBookingOption() {
        return this.$store.getters['guestBooking/selectedBookingOption']
      },

      miniviewQuestions() {
        return this.$store.getters['guestBooking/miniviewQuestions']
      },
    },

    methods: {
      saveQuestion() {
        if (! this.form.question.length) {
          this.$refs.miniviewQuestionsForm.setErrors({question: ['Write your question first']})
          return
        }

        this.addQuestion({
          body: this.form.question
        })

        this.form.question = ''
      },

      addQuestion(question) {
        this.questions.push(question)
      },

      updateQuestion(index, question) {
        this.questions.splice(index, 1, question)
      },

      removeQuestion(index, callback) {
        this.questions.splice(index, 1)
        callback()
      },

      saveMiniviewQuestions() {
        this.$store.commit('guestBooking/setMiniviewQuestions', this.questions)

        this.nextStep()
      },

      determineStepNumber() {
        let step = this.steps.findIndex(step => step.name == 'miniview-questions')

        if (step === -1) {
          return this.$router.push({ name: this.steps[0].route })
        }

        this.$store.commit('guestBooking/setCurrentStep', step + 1)
      },

      nextStep() {
        let nextStepRoute = this.steps[this.currentStep].route

        this.$router.push({ name: nextStepRoute })
      },

      prevStep() {
        let prevStepRoute = this.steps[this.currentStep - 2].route

        this.$router.push({ name: prevStepRoute })
      },
    },

    mounted() {
      this.determineStepNumber()

      if (! this.show || ! this.show.id) {
        this.$router.push({name: this.steps[0].route})
        return
      }

      if (! this.selectedBookingOption || this.selectedBookingOption.slug !== 'miniview') {
        this.$router.push({name: 'GuestBookingOptions'})
        return
      }

      if (this.miniviewQuestions.length) {
        this.questions = [
          ...this.miniviewQuestions
        ]
      }
    }
  }
</script>
